<template>
    <div class="navbar">
    <div class="w-100 d-flex align-items-center justify-content-between">
      <a href="/" class="d-flex flex-column no-decor" style="color:unset">
        <div class="d-flex flex-column align-items-start" style="font-size:2.5rem; line-height: normal">
          Знаем всё
          <span style="margin-top:-4px; letter-spacing: 2.2px; color:dimgrey; font-size:1rem">сам себе маркетолог</span>
        </div>
      </a>
      <div id='login-btns' class='login-btns' style="gap:18px">
        <a href="/login" class="btn btn-outline-primary no-decor">Вход</a>
        <a href="/register" class="btn btn-primary no-decor">Регистрация</a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    name: 'Navbar',
}
</script>


<style>
.login-btns{
  display: flex;
}

@media (max-width: 600px) {
  .login-btns {
    display: none !important;
    /* visibility: hidden; */
    /* width:0px; */
  }
}
</style>