<template>
    <div class="mt-5 d-flex flex-column align-items-center main-container" style="width:800px; max-width: 95%; margin:auto; margin-bottom:100px"> 
      <h1 class="mb-1">Отчет</h1>
        <div>{{ date }} : {{ address }}</div>
        <div class="mt-3"><strong>{{ name_ya }}</strong></div>
        <div>{{ company_desc }}</div>
        <h4 class="mt-4">SWOT анализ</h4>
        <div v-if=strength_set class="mt-3 w-100">
          <h6>Сильные стороны</h6>
          <ul v-if="strength_set.length">
            <li v-for="(strength, index) in strength_set" :key="index">
              {{ strength }}
            </li>
          </ul>
        </div>
        <div v-if=weakness_set class="mt-3 w-100">
          <h6>Слабые стороны</h6>
          <ul v-if="weakness_set.length">
            <li v-for="(weakness, index) in weakness_set" :key="index">
              {{ weakness }}
            </li>
          </ul>
        </div>
        <div v-if=opportunity_set class="mt-3 w-100">
          <h6>Возможности</h6>
          <ul v-if="opportunity_set.length">
            <li v-for="(opportunity, index) in opportunity_set" :key="index">
              {{ opportunity }}
            </li>
          </ul>
        </div>
        <div v-if=threat_set class="mt-3 w-100">
          <h6>Опасности</h6>
          <ul v-if="threat_set.length">
            <li v-for="(threat, index) in threat_set" :key="index">
              {{ threat }}
            </li>
          </ul>
        </div>
        <div v-if=competitor_set class="mt-5 align-items-center">
            <h3>Конкуренты <span style="font-size:60%">(в радиусе 1 километра)</span></h3>
            <table :class="{'table table-striped': competitor_set.length > 3, 'table': competitor_set.length <= 3}">
                <tbody>
                    <tr v-for="competitor in competitor_set" :key="competitor.id">
                    <td>{{ competitor.company.name_ya }}</td>
                    <td>{{ competitor.company.address }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div v-if=competitor_set class="mt-5 align-items-center usp-block">
            <h3>Уникальные торговые предложиения</h3>
            <div class='mb-5'>Чтобы выделиться на фоне конкурентов и привлечь их клиентов, важно использовать уникальное торговое предложение (УТП), которое будет основываться на недостатках конкурентов и акцентировать ваши сильные стороны. Вот несколько вариантов УТП, которые помогут вам создать привлекательные рекламные материалы:</div>
            <div v-for="(usp, index) in usp_set" :key="usp.id" class="d-flex flex-column align-items-start mb-3">
                    <h5 class="mb-3">{{ index + 1 }}. <strong>{{ usp.name }}</strong></h5>
                <ul style="gap:18px;">
                    <li><em>Пример УТП</em>: {{ usp.example }}</li>
                    <li><em>Комментарий</em>: {{ usp.comment }}</li>
                </ul>
            </div>
        </div>
        <div v-if="Object.keys(my_website_report).length > 0" class="w-100 mt-5 align-items-start">
          <h4>Анализ сайта</h4>
          <a class='no-decor' :href="my_website_report.url"><strong>{{ my_website_report.url }}</strong></a>
            <div>Качество главной страницы: {{ my_website_report.content_quality }}/10</div>
            <div class="mt-3">
              <h6>Позитивные аспекты</h6>
              <ul>
                <li v-for="(aspect, index) in positiveAspects" :key="index">
                  {{ aspect.name }}
                </li>
              </ul>

              <h6>Негативные аспекты</h6>
              <ul>
                <li v-for="(aspect, index) in negativeAspects" :key="index">
                  {{ aspect.name }}
                </li>
              </ul>
            </div>
            <div v-if=my_website_report.tone_analysis class="w-100 mt-5 align-items-start">
              <h5>Анализ тональности сайта</h5>
              <div v-html="formattedToneAnalysis"></div>
            </div>
        </div>
    </div>
</template>


<script>
import { ref, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import axios from '@/assets/js/axios.js'
  
export default {
    name: 'Report',
    components: {  },
    setup() {
    const route = useRoute()
    const reportData = ref(null)
    const loading = ref(true)
    const error = ref(null)
    const address = ref('')
    const date = ref('')
    const name_ya = ref('')
    const competitor_set = ref([])
    const usp_set = ref([])
    const my_website_report = ref({})
    const my_company_report = ref({})
    const report_line_set = ref([])


    const strength_set = computed(() => {
      return my_company_report.value.report_line_set
        ?.filter(report_line => report_line.category === 'strength')
        .map(report_line => report_line.name) || [];
    })
    const weakness_set = computed(() => {
      return my_company_report.value.report_line_set
        ?.filter(report_line => report_line.category === 'weakness')
        .map(report_line => report_line.name) || [];
    })
    const opportunity_set = computed(() => {
      return my_company_report.value.report_line_set
        ?.filter(report_line => report_line.category === 'opportunity')
        .map(report_line => report_line.name) || [];
    })
    const threat_set = computed(() => {
      return my_company_report.value.report_line_set
        ?.filter(report_line => report_line.category === 'threat')
        .map(report_line => report_line.name) || [];
    })
    const positiveAspects = computed(() => {
      return my_website_report.value.report_line_set?.filter(report_line => report_line.category === 'website_aspect_positive') || []
    })

    const negativeAspects = computed(() => {
      return my_website_report.value.report_line_set?.filter(report_line => report_line.category === 'website_aspect_negative') || []
    })
    
    const formattedToneAnalysis = computed(() =>{
      return my_website_report.value.tone_analysis.replace(/\n\n/g, '<br><br>') 
    })
 
    
    const fetchReportData = async () => {
      try {
        const { uuid } = route.params
        console.log(axios)
        const response = await axios.get(`/api/v1/reports/report/${uuid}`)
        console.log(response.data.date)
        reportData.value = response.data
        address.value = response.data.address
        date.value = response.data.date
        name_ya.value = response.data.name_ya
        competitor_set.value = response.data.competitor_set
        usp_set.value = response.data.usp_set
        my_website_report.value = response.data.my_website_report
        my_company_report.value = response.data.my_company_report
        report_line_set.value = response.data.report_line_set
        console.log('!', response.data)
      } catch (err) {
        error.value = err.message || 'Ошибка при загрузке данных'
      } finally {
        loading.value = false;
      }
    }

    onMounted(fetchReportData);
    
    return {
      reportData,
      loading,
      error,
      address,
      date,
      name_ya,
      competitor_set,
      usp_set,
      my_website_report,
      positiveAspects,
      negativeAspects,
      formattedToneAnalysis,
      strength_set,
      weakness_set,
      opportunity_set,
      threat_set,


    };
  },
};
</script>

<style scoped>
.main-container {
    font-family: 'Montserrat';
    font-size: clamp(15px, 3vw, 18px) !important;
}


li:not(:last-child) { 
   margin-bottom: 12px;  
}

.usp-block li{
    padding-left: 16px;
    line-height: 172%;
}
</style>