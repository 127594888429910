<template>
    <div class="main-block" style="width: fit-content; margin:auto; margin-top: 120px">
        <h3 style="">Собираем данные о компаниях</h3>
        <div v-if="loading">Минутку...</div>
        <div v-else-if="error">{{ error }}</div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import axios from '@/assets/js/axios.js'
  import { useReportsStore } from '@/store/modules/reports'

  export default {
    name: 'GettingCompetitors',
    components: {  },
  
    setup() {
      const router = useRouter()
      const loading = ref(true)
      const error = ref(null)
      const reportsStore = useReportsStore()
      const report_uuid = reportsStore.getReportUuid
      console.log('!getting competitors:', report_uuid)
      onMounted(async () => {
        try {
          const response = await axios.post(
            `/api/v1/reports/get_competitors`,
            { report_uuid: report_uuid }
          )
          console.log('Response data:', response.data);
          router.push(`/report/new/${report_uuid}`)
        } catch (err) {
          error.value = 'Ошибка загрузки списка компаний';
          console.error(err);
        } finally {
          loading.value = false;
        }
      });

  
      return {
        loading,
        error,
      };
    }
  };
  </script>