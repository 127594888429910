<template>
  <div id="payment-form" style="width:400px"></div>
</template>

<script>

import { ref, onMounted } from 'vue';
import axios from '@/assets/js/axios.js';
import { useReportsStore } from '@/store/modules/reports'

export default {
  name: 'PaymentForm',
  setup() {
    const options = ref(null)
    const reportsStore = useReportsStore()
    const report_uuid = reportsStore.getReportUuid
    console.log('!payment form:', report_uuid)
    const fetchOptions = async () => {

      try {
        const params = { tariff_id: 4 }
        if (report_uuid) {
          params.report_uuid = report_uuid
        }
        const response = await axios.get('api/v1/payments/get_payment_options', { params })
        options.value = response.data
        console.log('Fetched options:', options.value)
        
        initializeAssistant()
      } catch (error) {
        console.error('Error fetching options:', error)
      }
    }

    // Function to initialize the payment assistant
    const initializeAssistant = () => {
      if (!options.value) return // Exit if options are not yet loaded

      // Load the external script
      const script = document.createElement('script')
      script.src = 'https://payanyway.ru/assistant-builder'
      script.onload = () => {
        const assistant = new window.Assistant.Builder()

        // Set up callbacks for various payment statuses
        assistant.setOnSuccessCallback((operationId, transactionId) => {
          location.replace(`/report/getting_competitors`) // Redirect on success
        })

        assistant.setOnFailCallback((operationId, transactionId) => {
          console.error('Payment failed')
        })

        assistant.setOnInProgressCallback((operationId, transactionId) => {
          console.log('Payment in progress')
        })

        // Build the payment form with fetched options
        assistant.build(options.value, 'payment-form')
      }
      document.head.appendChild(script)
    }

    // Fetch options on component mount
    onMounted(fetchOptions)

    return {}
  },
}


  

</script>