// store/modules/clinics.js
import { defineStore } from 'pinia'

export const useReportsStore = defineStore('reports', {
  state: () => ({
    report_uuid: '',
  }),
  persist: true,

  actions: {
    setReportUuid(report_uuid) {
      this.report_uuid = report_uuid;
    },
    resetReportUuid() {
      this.report_uuid = ''
    },

    
  },
  getters: {
    getReportUuid() {
      return this.report_uuid;
    },
  },
});
