<template>
  <div id="app1" :style="wrapperStyles">
    <Navbar />
    <main class="content">
      <router-view />
    </main>
    <Footer />
  </div>
</template>

<script>
import { loadScript } from '@/assets/js/yandexMaps.js'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: { Navbar, Footer, },

setup() {
  const route = useRoute();
  const excludedRoutes = ['/', '/web_filters']; // List of routes to exclude

  const isRouteExcluded = computed(() => {
    return excludedRoutes.includes(route.path);
  });

  const wrapperStyles = computed(() => {
    return isRouteExcluded.value
      ? {}
      : {
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          // overflow: 'hidden'
        };
  });
  function adjustHeight() {
    const middleLists = document.querySelectorAll('.middle-list');

    middleLists.forEach(middleList => {
    const contentRect = middleList.getBoundingClientRect();
    const contentTop = contentRect.top
    const viewportHeight = window.innerHeight;

    const availableHeight = viewportHeight - contentTop;

    middleList.style.maxHeight = `${availableHeight}px`;
    middleList.style.Height = `${availableHeight}px`;
  })
}

  window.addEventListener('resize', adjustHeight);
  window.addEventListener('load', adjustHeight);
  window.addEventListener('click', adjustHeight);
  document.addEventListener('DOMContentLoaded', adjustHeight);
  return {
    wrapperStyles,
    adjustHeight
  };
},
mounted() {
  loadScript('https://api-maps.yandex.ru/2.1/?apikey=9ee0b24a-abd5-42f6-a5dd-e756f2f26a3b&lang=ru_RU')
    .then(() => {
      // You can initialize any global settings here if needed
    })
    .catch((error) => {
      console.error('Error loading Yandex Maps API script', error);
    });
}
};
</script>

<style scoped>
.content {
  flex: 1; 
  overflow-y: auto;
  padding-bottom: 80px;
}
</style>