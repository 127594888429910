import { createRouter, createWebHistory } from 'vue-router'

import Landing from '@/views/Landing.vue'
import Report from '@/views/Report.vue'
import PickMyCompany from '@/views/PickMyCompany.vue'
import Payment from '@/views/Payment.vue'
import GettingCompetitors from '@/views/GettingCompetitors.vue'


const routes = [
  { path: '/', name: 'landing', component: Landing },
  { path: '/report/:uuid', component: Report },
  { path: '/report/new/:uuid', component: PickMyCompany },
  { path: '/payment', component: Payment },
  { path: '/report/getting_competitors', component: GettingCompetitors },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});



export default router