<template>
    <footer class="footer">
        <div>Мой <a href="https://t.me/x6703045">ТГ</a>, если что-то пошло не так</div>
    </footer>
</template>


<script>

export default {
    name: 'Footer',
}
</script>


<style scoped>
  body, html {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .footer {
    display:flex;
    align-items: center;
    margin-top: auto;
    background-color: #f1f1f1; /* Optional: Adjust to your preferred styling */
    text-align: center;
    padding: 1em;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
  }

  .mt-5 {
    flex-grow: 1;
  }
</style>